export const ZZZAssault_2 = [
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 20.01,
    rank: 1,
    avg_round: 22565,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 6.0,
    rank: 2,
    avg_round: 20497,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 5.58,
    rank: 3,
    avg_round: 24070,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 3.38,
    rank: 4,
    avg_round: 16315,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 3.25,
    rank: 5,
    avg_round: 20206,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 3.03,
    rank: 6,
    avg_round: 22735,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 2.97,
    rank: 7,
    avg_round: 29547,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 2.65,
    rank: 8,
    avg_round: 19861,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 2.3,
    rank: 9,
    avg_round: 24185,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 2.12,
    rank: 10,
    avg_round: 14504,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 2.02,
    rank: 11,
    avg_round: 14600,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 1.9,
    rank: 12,
    avg_round: 15462,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 1.68,
    rank: 13,
    avg_round: 17332,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 1.11,
    rank: 14,
    avg_round: 19519,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 1.09,
    rank: 15,
    avg_round: 24989,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 1.07,
    rank: 16,
    avg_round: 20330,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 1.05,
    rank: 17,
    avg_round: 22385,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.89,
    rank: 18,
    avg_round: 20417,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.87,
    rank: 19,
    avg_round: 18259,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.87,
    rank: 19,
    avg_round: 15522,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.87,
    rank: 19,
    avg_round: 17898,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.83,
    rank: 22,
    avg_round: 19086,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.75,
    rank: 23,
    avg_round: 36231,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.73,
    rank: 24,
    avg_round: 17675,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.71,
    rank: 25,
    avg_round: 20730,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.65,
    rank: 26,
    avg_round: 26163,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.63,
    rank: 27,
    avg_round: 27011,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.61,
    rank: 28,
    avg_round: 17044,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.59,
    rank: 29,
    avg_round: 14612,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.55,
    rank: 30,
    avg_round: 24168,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.51,
    rank: 31,
    avg_round: 19930,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.49,
    rank: 32,
    avg_round: 22981,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.46,
    rank: 33,
    avg_round: 22382,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.46,
    rank: 33,
    avg_round: 21463,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.42,
    rank: 35,
    avg_round: 25875,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.38,
    rank: 36,
    avg_round: 24380,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.34,
    rank: 37,
    avg_round: 29771,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.34,
    rank: 37,
    avg_round: 19135,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.34,
    rank: 37,
    avg_round: 18046,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.32,
    rank: 40,
    avg_round: 20330,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.32,
    rank: 40,
    avg_round: 21152,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.32,
    rank: 40,
    avg_round: 14387,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.32,
    rank: 40,
    avg_round: 16833,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.3,
    rank: 44,
    avg_round: 18084,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.3,
    rank: 44,
    avg_round: 24771,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.3,
    rank: 44,
    avg_round: 17467,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.3,
    rank: 44,
    avg_round: 14887,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.28,
    rank: 48,
    avg_round: 21025,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.28,
    rank: 48,
    avg_round: 14122,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.28,
    rank: 48,
    avg_round: 20634,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.26,
    rank: 51,
    avg_round: 8887,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.26,
    rank: 51,
    avg_round: 18458,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.24,
    rank: 53,
    avg_round: 18361,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.24,
    rank: 53,
    avg_round: 22393,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.22,
    rank: 55,
    avg_round: 13857,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.22,
    rank: 55,
    avg_round: 17670,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.22,
    rank: 55,
    avg_round: 18085,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.22,
    rank: 55,
    avg_round: 19880,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.22,
    rank: 55,
    avg_round: 14941,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.22,
    rank: 55,
    avg_round: 13825,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 0.22,
    rank: 55,
    avg_round: 18311,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.22,
    rank: 55,
    avg_round: 11626,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.2,
    rank: 63,
    avg_round: 15006,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.2,
    rank: 63,
    avg_round: 21350,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.2,
    rank: 63,
    avg_round: 18734,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.2,
    rank: 63,
    avg_round: 31965,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.2,
    rank: 63,
    avg_round: 25705,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.18,
    rank: 68,
    avg_round: 20710,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.18,
    rank: 68,
    avg_round: 19664,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.18,
    rank: 68,
    avg_round: 23743,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 68,
    avg_round: 19479,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.18,
    rank: 68,
    avg_round: 9716,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 73,
    avg_round: 17484,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'anby-demara',
    app_rate: 0.16,
    rank: 73,
    avg_round: 12218,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 73,
    avg_round: 16907,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.16,
    rank: 73,
    avg_round: 13469,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 73,
    avg_round: 18151,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.16,
    rank: 73,
    avg_round: 10566,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 73,
    avg_round: 13303,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.16,
    rank: 73,
    avg_round: 8955,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.16,
    rank: 73,
    avg_round: 18772,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.16,
    rank: 73,
    avg_round: 16400,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.16,
    rank: 73,
    avg_round: 25642,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 73,
    avg_round: 16981,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.14,
    rank: 85,
    avg_round: 15665,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.14,
    rank: 85,
    avg_round: 16105,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.14,
    rank: 85,
    avg_round: 13463,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.14,
    rank: 85,
    avg_round: 14163,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 85,
    avg_round: 30489,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 85,
    avg_round: 23688,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.14,
    rank: 85,
    avg_round: 23912,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.14,
    rank: 85,
    avg_round: 12942,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 85,
    avg_round: 16355,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.14,
    rank: 85,
    avg_round: 23677,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.14,
    rank: 85,
    avg_round: 19638,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 96,
    avg_round: 12021,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.12,
    rank: 96,
    avg_round: 24763,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 96,
    avg_round: 18469,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.12,
    rank: 96,
    avg_round: 21892,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.12,
    rank: 96,
    avg_round: 12232,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 96,
    avg_round: 20706,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.12,
    rank: 96,
    avg_round: 19695,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.12,
    rank: 96,
    avg_round: 12517,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.12,
    rank: 96,
    avg_round: 21714,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 105,
    avg_round: 15866,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 105,
    avg_round: 18046,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 105,
    avg_round: 17615,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.1,
    rank: 105,
    avg_round: 11961,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.1,
    rank: 105,
    avg_round: 9140,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 105,
    avg_round: 32205,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 105,
    avg_round: 20240,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.1,
    rank: 105,
    avg_round: 25088,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 105,
    avg_round: 25999,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 105,
    avg_round: 26929,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 105,
    avg_round: 14774,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.1,
    rank: 105,
    avg_round: 21118,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.1,
    rank: 105,
    avg_round: 15874,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.1,
    rank: 105,
    avg_round: 15937,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'rina',
    app_rate: 0.1,
    rank: 105,
    avg_round: 16291,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 120,
    avg_round: 10835,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 120,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 120,
    avg_round: 10986,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 120,
    avg_round: 23158,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 120,
    avg_round: 14867,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.08,
    rank: 120,
    avg_round: 11395,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 120,
    avg_round: 23196,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 120,
    avg_round: 18008,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 120,
    avg_round: 20530,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 120,
    avg_round: 15266,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.08,
    rank: 120,
    avg_round: 14636,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 120,
    avg_round: 21866,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 120,
    avg_round: 18329,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 120,
    avg_round: 14657,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.08,
    rank: 120,
    avg_round: 11636,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 120,
    avg_round: 16712,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 120,
    avg_round: 16739,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 120,
    avg_round: 19163,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.08,
    rank: 120,
    avg_round: 12125,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.08,
    rank: 120,
    avg_round: 16824,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.08,
    rank: 120,
    avg_round: 9733,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'anby-demara',
    app_rate: 0.08,
    rank: 120,
    avg_round: 15600,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 120,
    avg_round: 24340,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 143,
    avg_round: 10840,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 143,
    avg_round: 7023,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 143,
    avg_round: 21162,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 143,
    avg_round: 19823,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 143,
    avg_round: 15806,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 143,
    avg_round: 23841,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 143,
    avg_round: 14923,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 143,
    avg_round: 16829,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 143,
    avg_round: 23530,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'koleda',
    app_rate: 0.06,
    rank: 143,
    avg_round: 11783,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 143,
    avg_round: 24876,
    star_num: '4'
  },
  {
    char_one: 'anby-demara',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 143,
    avg_round: 25766,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 143,
    avg_round: 11062,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 143,
    avg_round: 16650,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 143,
    avg_round: 18968,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 143,
    avg_round: 14300,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 143,
    avg_round: 17070,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 143,
    avg_round: 14410,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 143,
    avg_round: 20915,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 143,
    avg_round: 20384,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'harumasa',
    char_three: 'qingyi',
    app_rate: 0.06,
    rank: 143,
    avg_round: 9237,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 143,
    avg_round: 24890,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 143,
    avg_round: 22894,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'jane-doe',
    app_rate: 0.06,
    rank: 143,
    avg_round: 11480,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 143,
    avg_round: 11247,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 143,
    avg_round: 19443,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 143,
    avg_round: 14250,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'anby-demara',
    app_rate: 0.06,
    rank: 143,
    avg_round: 10652,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 143,
    avg_round: 14111,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 143,
    avg_round: 10175,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 143,
    avg_round: 29129,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 143,
    avg_round: 9295,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 143,
    avg_round: 19661,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 143,
    avg_round: 27022,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 143,
    avg_round: 24770,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 143,
    avg_round: 12432,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 143,
    avg_round: 20919,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'grace-howard',
    app_rate: 0.06,
    rank: 143,
    avg_round: 10995,
    star_num: '4'
  }
];
