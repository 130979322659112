export const ZZZAssault_0 = [
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 19.35,
    rank: 1,
    avg_round: 22083,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 14.87,
    rank: 2,
    avg_round: 20648,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 11.84,
    rank: 3,
    avg_round: 23341,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 11.77,
    rank: 4,
    avg_round: 30770,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 10.06,
    rank: 5,
    avg_round: 22726,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 8.13,
    rank: 6,
    avg_round: 21966,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 6.67,
    rank: 7,
    avg_round: 23565,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 5.79,
    rank: 8,
    avg_round: 12175,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 5.54,
    rank: 9,
    avg_round: 23441,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 5.41,
    rank: 10,
    avg_round: 20497,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 4.34,
    rank: 11,
    avg_round: 21752,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 3.55,
    rank: 12,
    avg_round: 31180,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 3.55,
    rank: 12,
    avg_round: 27562,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 3.48,
    rank: 14,
    avg_round: 23044,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 3.44,
    rank: 15,
    avg_round: 27044,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 3.35,
    rank: 16,
    avg_round: 21029,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 3.3,
    rank: 17,
    avg_round: 15796,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 2.93,
    rank: 18,
    avg_round: 20206,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 2.9,
    rank: 19,
    avg_round: 19169,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 2.75,
    rank: 20,
    avg_round: 22673,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 2.66,
    rank: 21,
    avg_round: 24898,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 2.64,
    rank: 22,
    avg_round: 13886,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 2.51,
    rank: 23,
    avg_round: 19716,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 2.46,
    rank: 24,
    avg_round: 26510,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 2.35,
    rank: 25,
    avg_round: 23512,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 2.33,
    rank: 26,
    avg_round: 28659,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 2.33,
    rank: 26,
    avg_round: 21423,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 2.31,
    rank: 28,
    avg_round: 16799,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 2.22,
    rank: 29,
    avg_round: 14328,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 2.15,
    rank: 30,
    avg_round: 21400,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 2.1,
    rank: 31,
    avg_round: 18024,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 2.04,
    rank: 32,
    avg_round: 17273,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 1.91,
    rank: 33,
    avg_round: 14504,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 1.88,
    rank: 34,
    avg_round: 14340,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.88,
    rank: 34,
    avg_round: 14337,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 1.84,
    rank: 36,
    avg_round: 14600,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 1.84,
    rank: 36,
    avg_round: 15272,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 1.6,
    rank: 38,
    avg_round: 19744,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.55,
    rank: 39,
    avg_round: 14996,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 1.53,
    rank: 40,
    avg_round: 24378,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 1.51,
    rank: 41,
    avg_round: 17332,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 1.48,
    rank: 42,
    avg_round: 18764,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 1.44,
    rank: 43,
    avg_round: 19129,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 1.24,
    rank: 44,
    avg_round: 11089,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.2,
    rank: 45,
    avg_round: 23233,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 1.18,
    rank: 46,
    avg_round: 23759,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 1.15,
    rank: 47,
    avg_round: 24504,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 1.07,
    rank: 48,
    avg_round: 11324,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 1.07,
    rank: 48,
    avg_round: 20242,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.07,
    rank: 48,
    avg_round: 16123,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 1.04,
    rank: 51,
    avg_round: 19519,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.98,
    rank: 52,
    avg_round: 22836,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.97,
    rank: 53,
    avg_round: 22213,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.97,
    rank: 53,
    avg_round: 30091,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.87,
    rank: 55,
    avg_round: 17006,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.86,
    rank: 56,
    avg_round: 23247,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.86,
    rank: 56,
    avg_round: 15429,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.84,
    rank: 58,
    avg_round: 19220,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.84,
    rank: 58,
    avg_round: 13057,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.82,
    rank: 60,
    avg_round: 17049,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.8,
    rank: 61,
    avg_round: 14828,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.8,
    rank: 61,
    avg_round: 19673,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.78,
    rank: 63,
    avg_round: 18259,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.78,
    rank: 63,
    avg_round: 20898,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'soukaku',
    app_rate: 0.78,
    rank: 63,
    avg_round: 15009,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.78,
    rank: 63,
    avg_round: 15522,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.77,
    rank: 67,
    avg_round: 27996,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.75,
    rank: 68,
    avg_round: 15649,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.71,
    rank: 69,
    avg_round: 21970,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.71,
    rank: 69,
    avg_round: 23475,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 0.71,
    rank: 69,
    avg_round: 13844,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.69,
    rank: 72,
    avg_round: 15594,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'astra-yao',
    app_rate: 0.69,
    rank: 72,
    avg_round: 18776,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.67,
    rank: 74,
    avg_round: 20360,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.67,
    rank: 74,
    avg_round: 21831,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.67,
    rank: 74,
    avg_round: 20724,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.64,
    rank: 77,
    avg_round: 23673,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.6,
    rank: 78,
    avg_round: 20551,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'soukaku',
    app_rate: 0.58,
    rank: 79,
    avg_round: 18120,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.56,
    rank: 80,
    avg_round: 10504,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.56,
    rank: 80,
    avg_round: 19281,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.55,
    rank: 82,
    avg_round: 20215,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.55,
    rank: 82,
    avg_round: 17044,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.55,
    rank: 82,
    avg_round: 22842,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.53,
    rank: 85,
    avg_round: 17562,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.51,
    rank: 86,
    avg_round: 20430,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.51,
    rank: 86,
    avg_round: 19048,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.51,
    rank: 86,
    avg_round: 12333,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.51,
    rank: 86,
    avg_round: 28408,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.49,
    rank: 90,
    avg_round: 22307,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'astra-yao',
    app_rate: 0.46,
    rank: 91,
    avg_round: 20282,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.46,
    rank: 91,
    avg_round: 21525,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.46,
    rank: 91,
    avg_round: 25979,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.46,
    rank: 91,
    avg_round: 19930,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.46,
    rank: 91,
    avg_round: 21386,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.44,
    rank: 96,
    avg_round: 29578,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lycaon',
    app_rate: 0.44,
    rank: 96,
    avg_round: 18912,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.42,
    rank: 98,
    avg_round: 22382,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.42,
    rank: 98,
    avg_round: 16178,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.42,
    rank: 98,
    avg_round: 21558,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.4,
    rank: 101,
    avg_round: 27852,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.4,
    rank: 101,
    avg_round: 11569,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.38,
    rank: 103,
    avg_round: 17642,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.38,
    rank: 103,
    avg_round: 12250,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.38,
    rank: 103,
    avg_round: 8718,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.36,
    rank: 106,
    avg_round: 19055,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.36,
    rank: 106,
    avg_round: 24380,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.35,
    rank: 108,
    avg_round: 16907,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.35,
    rank: 108,
    avg_round: 18376,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.35,
    rank: 108,
    avg_round: 20057,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'soukaku',
    app_rate: 0.35,
    rank: 108,
    avg_round: 12433,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.35,
    rank: 108,
    avg_round: 19391,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.35,
    rank: 108,
    avg_round: 19076,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.33,
    rank: 114,
    avg_round: 16929,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.33,
    rank: 114,
    avg_round: 17973,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.33,
    rank: 114,
    avg_round: 19135,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.33,
    rank: 114,
    avg_round: 18068,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.33,
    rank: 114,
    avg_round: 9015,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.31,
    rank: 119,
    avg_round: 20442,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.31,
    rank: 119,
    avg_round: 16629,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.31,
    rank: 119,
    avg_round: 17486,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'caesar',
    app_rate: 0.29,
    rank: 122,
    avg_round: 14387,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.27,
    rank: 123,
    avg_round: 12733,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.27,
    rank: 123,
    avg_round: 7869,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.27,
    rank: 123,
    avg_round: 17467,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.27,
    rank: 123,
    avg_round: 18886,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.27,
    rank: 123,
    avg_round: 14887,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.26,
    rank: 128,
    avg_round: 17206,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.26,
    rank: 128,
    avg_round: 22092,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.26,
    rank: 128,
    avg_round: 16082,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.26,
    rank: 128,
    avg_round: 14122,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.26,
    rank: 128,
    avg_round: 12403,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.24,
    rank: 133,
    avg_round: 18458,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.24,
    rank: 133,
    avg_round: 22233,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.24,
    rank: 133,
    avg_round: 17595,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.24,
    rank: 133,
    avg_round: 15613,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.24,
    rank: 133,
    avg_round: 20901,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.24,
    rank: 133,
    avg_round: 17220,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.22,
    rank: 139,
    avg_round: 18361,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.22,
    rank: 139,
    avg_round: 12419,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'nicole-demara',
    app_rate: 0.22,
    rank: 139,
    avg_round: 16370,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.22,
    rank: 139,
    avg_round: 23069,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'rina',
    app_rate: 0.22,
    rank: 139,
    avg_round: 18397,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.22,
    rank: 139,
    avg_round: 13509,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.22,
    rank: 139,
    avg_round: 17283,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.22,
    rank: 139,
    avg_round: 13083,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.2,
    rank: 147,
    avg_round: 13857,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.2,
    rank: 147,
    avg_round: 12502,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.2,
    rank: 147,
    avg_round: 21401,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.2,
    rank: 147,
    avg_round: 17670,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.2,
    rank: 147,
    avg_round: 12364,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.2,
    rank: 147,
    avg_round: 21216,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.2,
    rank: 147,
    avg_round: 18085,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.2,
    rank: 147,
    avg_round: 19880,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.2,
    rank: 147,
    avg_round: 21302,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.2,
    rank: 147,
    avg_round: 19618,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.2,
    rank: 147,
    avg_round: 22735,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.2,
    rank: 147,
    avg_round: 16744,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.2,
    rank: 147,
    avg_round: 10628,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.2,
    rank: 147,
    avg_round: 13825,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.2,
    rank: 147,
    avg_round: 13002,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.2,
    rank: 147,
    avg_round: 14219,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.18,
    rank: 163,
    avg_round: 11366,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'rina',
    app_rate: 0.18,
    rank: 163,
    avg_round: 14222,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.18,
    rank: 163,
    avg_round: 15006,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.18,
    rank: 163,
    avg_round: 17416,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 163,
    avg_round: 19673,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.18,
    rank: 163,
    avg_round: 15009,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.18,
    rank: 163,
    avg_round: 19308,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.18,
    rank: 163,
    avg_round: 20800,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.18,
    rank: 163,
    avg_round: 18734,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.18,
    rank: 163,
    avg_round: 13002,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.18,
    rank: 163,
    avg_round: 22814,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 175,
    avg_round: 17462,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 175,
    avg_round: 17853,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.16,
    rank: 175,
    avg_round: 10286,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.16,
    rank: 175,
    avg_round: 14096,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 175,
    avg_round: 20710,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 175,
    avg_round: 19664,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 175,
    avg_round: 23070,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'rina',
    app_rate: 0.16,
    rank: 175,
    avg_round: 12281,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.16,
    rank: 175,
    avg_round: 11367,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.16,
    rank: 175,
    avg_round: 8201,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.16,
    rank: 175,
    avg_round: 17375,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.16,
    rank: 175,
    avg_round: 20788,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.16,
    rank: 175,
    avg_round: 9837,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'anby-demara',
    app_rate: 0.15,
    rank: 188,
    avg_round: 12218,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.15,
    rank: 188,
    avg_round: 20390,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 188,
    avg_round: 18415,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.15,
    rank: 188,
    avg_round: 11278,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 188,
    avg_round: 16907,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'seth',
    app_rate: 0.15,
    rank: 188,
    avg_round: 13469,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.15,
    rank: 188,
    avg_round: 21010,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 188,
    avg_round: 18772,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.15,
    rank: 188,
    avg_round: 17224,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 188,
    avg_round: 16400,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.15,
    rank: 188,
    avg_round: 22737,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.15,
    rank: 188,
    avg_round: 13568,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 188,
    avg_round: 16981,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.13,
    rank: 201,
    avg_round: 12644,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'ben',
    app_rate: 0.13,
    rank: 201,
    avg_round: 8391,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.13,
    rank: 201,
    avg_round: 20316,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 201,
    avg_round: 18626,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.13,
    rank: 201,
    avg_round: 16082,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.13,
    rank: 201,
    avg_round: 13463,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'yanagi',
    app_rate: 0.13,
    rank: 201,
    avg_round: 17045,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 201,
    avg_round: 13988,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.13,
    rank: 201,
    avg_round: 14163,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 201,
    avg_round: 16482,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 201,
    avg_round: 17040,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.13,
    rank: 201,
    avg_round: 18344,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.13,
    rank: 201,
    avg_round: 23688,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'grace-howard',
    app_rate: 0.13,
    rank: 201,
    avg_round: 10601,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 201,
    avg_round: 14066,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.13,
    rank: 201,
    avg_round: 16134,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.13,
    rank: 201,
    avg_round: 10185,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.13,
    rank: 201,
    avg_round: 15810,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'qingyi',
    app_rate: 0.13,
    rank: 201,
    avg_round: 12942,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.11,
    rank: 221,
    avg_round: 19361,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.11,
    rank: 221,
    avg_round: 16053,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 221,
    avg_round: 22243,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 221,
    avg_round: 22247,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.11,
    rank: 221,
    avg_round: 18264,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.11,
    rank: 221,
    avg_round: 16530,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 221,
    avg_round: 16085,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.11,
    rank: 221,
    avg_round: 8352,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.11,
    rank: 221,
    avg_round: 18877,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 221,
    avg_round: 15083,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 221,
    avg_round: 21892,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 221,
    avg_round: 12232,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 221,
    avg_round: 11159,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 221,
    avg_round: 13559,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.11,
    rank: 221,
    avg_round: 14038,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 221,
    avg_round: 21714,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 239,
    avg_round: 20004,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 239,
    avg_round: 17261,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 239,
    avg_round: 14048,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 239,
    avg_round: 13553,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 239,
    avg_round: 18092,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 239,
    avg_round: 15866,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 239,
    avg_round: 14715,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 239,
    avg_round: 9896,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 239,
    avg_round: 23787,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 239,
    avg_round: 22269,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.09,
    rank: 239,
    avg_round: 12398,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 239,
    avg_round: 18295,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 239,
    avg_round: 11323,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 239,
    avg_round: 13303,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.09,
    rank: 239,
    avg_round: 11961,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.09,
    rank: 239,
    avg_round: 18054,
    star_num: '4'
  },
  {
    char_one: 'koleda',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.09,
    rank: 239,
    avg_round: 9651,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.09,
    rank: 239,
    avg_round: 12984,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'lycaon',
    app_rate: 0.09,
    rank: 239,
    avg_round: 9988,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'qingyi',
    app_rate: 0.09,
    rank: 239,
    avg_round: 12622,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 239,
    avg_round: 12333,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 239,
    avg_round: 13188,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 239,
    avg_round: 13949,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 239,
    avg_round: 14774,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.09,
    rank: 239,
    avg_round: 14215,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 239,
    avg_round: 19982,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 239,
    avg_round: 15874,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 239,
    avg_round: 8966,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'rina',
    app_rate: 0.09,
    rank: 239,
    avg_round: 16291,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'seth',
    app_rate: 0.09,
    rank: 239,
    avg_round: 10595,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.09,
    rank: 239,
    avg_round: 8428,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 239,
    avg_round: 28104,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 273,
    avg_round: 22049,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 273,
    avg_round: 19512,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 273,
    avg_round: 14383,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'koleda',
    app_rate: 0.07,
    rank: 273,
    avg_round: 10580,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 273,
    avg_round: 14944,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 273,
    avg_round: 10986,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 273,
    avg_round: 23158,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.07,
    rank: 273,
    avg_round: 11395,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 273,
    avg_round: 16650,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 273,
    avg_round: 12075,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 273,
    avg_round: 20530,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 273,
    avg_round: 17121,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 273,
    avg_round: 15266,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 273,
    avg_round: 21866,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 273,
    avg_round: 12598,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.07,
    rank: 273,
    avg_round: 11636,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'grace-howard',
    char_three: 'lycaon',
    app_rate: 0.07,
    rank: 273,
    avg_round: 7460,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 273,
    avg_round: 16712,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 273,
    avg_round: 16739,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.07,
    rank: 273,
    avg_round: 19378,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 273,
    avg_round: 14109,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'astra-yao',
    char_three: 'ben',
    app_rate: 0.07,
    rank: 273,
    avg_round: 11233,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.07,
    rank: 273,
    avg_round: 9178,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.07,
    rank: 273,
    avg_round: 8524,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.07,
    rank: 273,
    avg_round: 10163,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 273,
    avg_round: 10562,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'caesar',
    app_rate: 0.07,
    rank: 273,
    avg_round: 10201,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.07,
    rank: 273,
    avg_round: 14380,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 307,
    avg_round: 15806,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.05,
    rank: 307,
    avg_round: 7437,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 307,
    avg_round: 14923,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 307,
    avg_round: 14585,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 307,
    avg_round: 10513,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 307,
    avg_round: 14096,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 307,
    avg_round: 25268,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 307,
    avg_round: 11091,
    star_num: '4'
  },
  {
    char_one: 'lycaon',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 307,
    avg_round: 19635,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.05,
    rank: 307,
    avg_round: 12214,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 307,
    avg_round: 18968,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 307,
    avg_round: 14115,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 307,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 307,
    avg_round: 14300,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 307,
    avg_round: 17070,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 307,
    avg_round: 20915,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 307,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 307,
    avg_round: 17751,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 307,
    avg_round: 20384,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 307,
    avg_round: 8627,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'yanagi',
    char_three: 'jane-doe',
    app_rate: 0.05,
    rank: 307,
    avg_round: 11480,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 307,
    avg_round: 11543,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 307,
    avg_round: 17756,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.05,
    rank: 307,
    avg_round: 11247,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.05,
    rank: 307,
    avg_round: 12677,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.05,
    rank: 307,
    avg_round: 17070,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 307,
    avg_round: 9071,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.05,
    rank: 307,
    avg_round: 19661,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'seth',
    app_rate: 0.05,
    rank: 307,
    avg_round: 12432,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'ben',
    app_rate: 0.05,
    rank: 307,
    avg_round: 18385,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.05,
    rank: 307,
    avg_round: 9923,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'soukaku',
    app_rate: 0.05,
    rank: 307,
    avg_round: 11272,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'corin',
    char_three: 'rina',
    app_rate: 0.04,
    rank: 359,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 359,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'lucy',
    app_rate: 0.04,
    rank: 359,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.04,
    rank: 359,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'grace-howard',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'qingyi',
    char_three: 'lighter',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'billy-kid',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lycaon',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anton',
    char_three: 'harumasa',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'nekomata',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'harumasa',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'billy-kid',
    char_three: 'lighter',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'grace-howard',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lighter',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'qingyi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'koleda',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'lycaon',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'qingyi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'koleda',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'lycaon',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nekomata',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'lycaon',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'jane-doe',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'corin',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'anton',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'yanagi',
    char_three: 'lycaon',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'koleda',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'ellen',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'anby-demara',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'anby-demara',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nekomata',
    char_three: 'nicole-demara',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nekomata',
    char_three: 'jane-doe',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'qingyi',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'yanagi',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'harumasa',
    char_two: 'grace-howard',
    char_three: 'lighter',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'ben',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.02,
    rank: 460,
    avg_round: 0,
    star_num: '4'
  }
];
