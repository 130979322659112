export const ZZZAssault_1 = [
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 14.35,
    rank: 1,
    avg_round: 20572,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 10.93,
    rank: 2,
    avg_round: 30629,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 5.99,
    rank: 3,
    avg_round: 23394,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 4.02,
    rank: 4,
    avg_round: 21440,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 3.57,
    rank: 5,
    avg_round: 10755,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 3.31,
    rank: 6,
    avg_round: 27103,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 3.27,
    rank: 7,
    avg_round: 31114,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 2.82,
    rank: 8,
    avg_round: 22540,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 2.55,
    rank: 9,
    avg_round: 24818,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 2.18,
    rank: 10,
    avg_round: 21350,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 1.52,
    rank: 11,
    avg_round: 19803,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 1.45,
    rank: 12,
    avg_round: 14454,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.35,
    rank: 13,
    avg_round: 24338,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 1.32,
    rank: 14,
    avg_round: 20912,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 1.32,
    rank: 14,
    avg_round: 18601,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 1.22,
    rank: 16,
    avg_round: 15813,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 1.01,
    rank: 17,
    avg_round: 12868,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.94,
    rank: 18,
    avg_round: 20155,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.9,
    rank: 19,
    avg_round: 12232,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.88,
    rank: 20,
    avg_round: 23981,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'soukaku',
    app_rate: 0.85,
    rank: 21,
    avg_round: 19376,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'nicole-demara',
    app_rate: 0.81,
    rank: 22,
    avg_round: 22987,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'soukaku',
    app_rate: 0.81,
    rank: 22,
    avg_round: 15009,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.77,
    rank: 24,
    avg_round: 21610,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.7,
    rank: 25,
    avg_round: 29177,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'astra-yao',
    app_rate: 0.7,
    rank: 25,
    avg_round: 18776,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.6,
    rank: 27,
    avg_round: 23331,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.58,
    rank: 28,
    avg_round: 20441,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.56,
    rank: 29,
    avg_round: 19114,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.56,
    rank: 29,
    avg_round: 20832,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.54,
    rank: 31,
    avg_round: 19439,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.53,
    rank: 32,
    avg_round: 12628,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'soukaku',
    app_rate: 0.53,
    rank: 32,
    avg_round: 18675,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.51,
    rank: 34,
    avg_round: 17562,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.51,
    rank: 34,
    avg_round: 20476,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.49,
    rank: 36,
    avg_round: 20798,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.49,
    rank: 36,
    avg_round: 17055,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.47,
    rank: 38,
    avg_round: 13099,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'astra-yao',
    app_rate: 0.47,
    rank: 38,
    avg_round: 20282,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.45,
    rank: 40,
    avg_round: 20344,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lycaon',
    app_rate: 0.45,
    rank: 40,
    avg_round: 18912,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.45,
    rank: 40,
    avg_round: 22408,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 0.39,
    rank: 43,
    avg_round: 12444,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.38,
    rank: 44,
    avg_round: 27604,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.38,
    rank: 44,
    avg_round: 26903,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.34,
    rank: 46,
    avg_round: 15118,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.32,
    rank: 47,
    avg_round: 21764,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'astra-yao',
    app_rate: 0.3,
    rank: 48,
    avg_round: 16434,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'soukaku',
    app_rate: 0.3,
    rank: 48,
    avg_round: 12100,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.28,
    rank: 50,
    avg_round: 17987,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.28,
    rank: 50,
    avg_round: 9576,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.28,
    rank: 50,
    avg_round: 17272,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.24,
    rank: 53,
    avg_round: 15331,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.23,
    rank: 54,
    avg_round: 19357,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 54,
    avg_round: 14683,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'nicole-demara',
    app_rate: 0.23,
    rank: 54,
    avg_round: 16370,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.23,
    rank: 54,
    avg_round: 10587,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.23,
    rank: 54,
    avg_round: 18158,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'rina',
    app_rate: 0.23,
    rank: 54,
    avg_round: 8163,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'seth',
    app_rate: 0.23,
    rank: 54,
    avg_round: 16381,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.21,
    rank: 61,
    avg_round: 15613,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.21,
    rank: 61,
    avg_round: 18258,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.19,
    rank: 63,
    avg_round: 19090,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'astra-yao',
    app_rate: 0.17,
    rank: 64,
    avg_round: 21955,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 64,
    avg_round: 19726,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.17,
    rank: 64,
    avg_round: 19308,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 64,
    avg_round: 22244,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.17,
    rank: 64,
    avg_round: 13480,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.17,
    rank: 64,
    avg_round: 17375,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.15,
    rank: 70,
    avg_round: 20390,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.15,
    rank: 70,
    avg_round: 21094,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 70,
    avg_round: 14892,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'anby-demara',
    char_three: 'soukaku',
    app_rate: 0.15,
    rank: 70,
    avg_round: 7420,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.15,
    rank: 70,
    avg_round: 12262,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.15,
    rank: 70,
    avg_round: 18885,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.15,
    rank: 70,
    avg_round: 12823,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.15,
    rank: 70,
    avg_round: 8373,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 78,
    avg_round: 8855,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.13,
    rank: 78,
    avg_round: 11432,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'rina',
    app_rate: 0.13,
    rank: 78,
    avg_round: 14222,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.13,
    rank: 78,
    avg_round: 24525,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 78,
    avg_round: 13988,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'qingyi',
    char_three: 'soukaku',
    app_rate: 0.13,
    rank: 78,
    avg_round: 14016,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'nicole-demara',
    app_rate: 0.13,
    rank: 78,
    avg_round: 14066,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 78,
    avg_round: 13424,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.13,
    rank: 78,
    avg_round: 16091,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.11,
    rank: 87,
    avg_round: 14496,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 87,
    avg_round: 18626,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'rina',
    app_rate: 0.11,
    rank: 87,
    avg_round: 14432,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'yanagi',
    app_rate: 0.11,
    rank: 87,
    avg_round: 17045,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 87,
    avg_round: 21980,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 87,
    avg_round: 8366,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 93,
    avg_round: 10581,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 93,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 93,
    avg_round: 9267,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'lycaon',
    app_rate: 0.09,
    rank: 93,
    avg_round: 9988,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 93,
    avg_round: 15748,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 93,
    avg_round: 22095,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'rina',
    app_rate: 0.09,
    rank: 93,
    avg_round: 10592,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'nicole-demara',
    app_rate: 0.09,
    rank: 93,
    avg_round: 18266,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'rina',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 93,
    avg_round: 9967,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 93,
    avg_round: 20482,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 103,
    avg_round: 10025,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 103,
    avg_round: 13406,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 103,
    avg_round: 11921,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 103,
    avg_round: 22292,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 103,
    avg_round: 14890,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'koleda',
    char_three: 'nicole-demara',
    app_rate: 0.08,
    rank: 103,
    avg_round: 12230,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.08,
    rank: 103,
    avg_round: 20217,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'soukaku',
    app_rate: 0.08,
    rank: 103,
    avg_round: 16870,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 103,
    avg_round: 21380,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.08,
    rank: 103,
    avg_round: 22568,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'caesar',
    app_rate: 0.08,
    rank: 103,
    avg_round: 10201,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 114,
    avg_round: 20296,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 114,
    avg_round: 12214,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 114,
    avg_round: 12591,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 114,
    avg_round: 18884,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'grace-howard',
    app_rate: 0.06,
    rank: 114,
    avg_round: 11474,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'grace-howard',
    char_three: 'lycaon',
    app_rate: 0.06,
    rank: 114,
    avg_round: 7460,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 114,
    avg_round: 14548,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.06,
    rank: 114,
    avg_round: 24738,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 114,
    avg_round: 19305,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 114,
    avg_round: 12300,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'soukaku',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 114,
    avg_round: 12132,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'ellen',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 114,
    avg_round: 6277,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'miyabi',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 114,
    avg_round: 19405,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 114,
    avg_round: 27177,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'anby-demara',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 114,
    avg_round: 13255,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'miyabi',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 114,
    avg_round: 11272,
    star_num: '4'
  }
];
