export const ZZZDeadlyCharData = [
  {
    char: 'anby-demara',
    name: 'Anby',
    rarity: '4*',
    dupes_score: 17831,
    current_usage: 13.47,
    prev_usage: 17.15,
    current_score: 15134,
    prev_score: 15947,
    boss_1_usage: 1.28,
    boss_1_score: 10343,
    boss_2_usage: 13.24,
    boss_2_score: 15692,
    boss_3_usage: 0.36,
    boss_3_score: 0
  },
  {
    char: 'anton',
    name: 'Anton',
    rarity: '4*',
    dupes_score: 0,
    current_usage: 0.86,
    prev_usage: 0.97,
    current_score: 17455,
    prev_score: 18106,
    boss_1_usage: 0.04,
    boss_1_score: 0,
    boss_2_usage: 0.89,
    boss_2_score: 17637,
    boss_3_usage: 0.02,
    boss_3_score: 0
  },
  {
    char: 'astra-yao',
    name: 'Astra Yao',
    rarity: 'Limited 5*',
    dupes_score: '',
    current_usage: 65.34,
    prev_usage: 65.34,
    current_score: 24234,
    prev_score: 24234,
    boss_1_usage: 32.04,
    boss_1_score: 25987,
    boss_2_usage: 24.7,
    boss_2_score: 23247,
    boss_3_usage: 14.12,
    boss_3_score: 22151
  },
  {
    char: 'ben',
    name: 'Ben',
    rarity: '4*',
    dupes_score: 0,
    current_usage: 1.57,
    prev_usage: 1.14,
    current_score: 13131,
    prev_score: 15454,
    boss_1_usage: 0.11,
    boss_1_score: 0,
    boss_2_usage: 0.1,
    boss_2_score: 0,
    boss_3_usage: 1.6,
    boss_3_score: 13371
  },
  {
    char: 'billy-kid',
    name: 'Billy',
    rarity: '4*',
    dupes_score: 0,
    current_usage: 0.8,
    prev_usage: 1.03,
    current_score: 14471,
    prev_score: 19837,
    boss_1_usage: 0.24,
    boss_1_score: 0,
    boss_2_usage: 0.26,
    boss_2_score: 0,
    boss_3_usage: 0.38,
    boss_3_score: 0
  },
  {
    char: 'burnice',
    name: 'Burnice',
    rarity: 'Limited 5*',
    dupes_score: 31872,
    current_usage: 55.51,
    prev_usage: 46.17,
    current_score: 22409,
    prev_score: 24681,
    boss_1_usage: 3.52,
    boss_1_score: 24072,
    boss_2_usage: 2.57,
    boss_2_score: 23763,
    boss_3_usage: 58.18,
    boss_3_score: 22256
  },
  {
    char: 'caesar',
    name: 'Caesar',
    rarity: 'Limited 5*',
    dupes_score: 29906,
    current_usage: 44.09,
    prev_usage: 46.69,
    current_score: 19200,
    prev_score: 21550,
    boss_1_usage: 9.36,
    boss_1_score: 17603,
    boss_2_usage: 10.67,
    boss_2_score: 20902,
    boss_3_usage: 29.71,
    boss_3_score: 19019
  },
  {
    char: 'corin',
    name: 'Corin',
    rarity: '4*',
    dupes_score: 21278,
    current_usage: 0.86,
    prev_usage: 1.67,
    current_score: 12838,
    prev_score: 14961,
    boss_1_usage: 0.21,
    boss_1_score: 0,
    boss_2_usage: 0.4,
    boss_2_score: 15466,
    boss_3_usage: 0.34,
    boss_3_score: 0
  },
  {
    char: 'ellen',
    name: 'Ellen',
    rarity: 'Limited 5*',
    dupes_score: 18563,
    current_usage: 22.07,
    prev_usage: 40.38,
    current_score: 13561,
    prev_score: 17465,
    boss_1_usage: 12.88,
    boss_1_score: 12160,
    boss_2_usage: 8.3,
    boss_2_score: 16526,
    boss_3_usage: 2.49,
    boss_3_score: 11198
  },
  {
    char: 'grace-howard',
    name: 'Grace',
    rarity: 'Standard 5*',
    dupes_score: 22120,
    current_usage: 8.04,
    prev_usage: 9.02,
    current_score: 16490,
    prev_score: 16741,
    boss_1_usage: 3.2,
    boss_1_score: 18841,
    boss_2_usage: 4.45,
    boss_2_score: 15625,
    boss_3_usage: 1.09,
    boss_3_score: 13674
  },
  {
    char: 'harumasa',
    name: 'Harumasa',
    rarity: 'Limited 5*',
    dupes_score: 23782,
    current_usage: 27.05,
    prev_usage: 29.31,
    current_score: 18099,
    prev_score: 17441,
    boss_1_usage: 1.51,
    boss_1_score: 13386,
    boss_2_usage: 28.22,
    boss_2_score: 18308,
    boss_3_usage: 0.21,
    boss_3_score: 0
  },
  {
    char: 'jane-doe',
    name: 'Jane Doe',
    rarity: 'Limited 5*',
    dupes_score: 22845,
    current_usage: 44.67,
    prev_usage: 34.95,
    current_score: 20034,
    prev_score: 18847,
    boss_1_usage: 2.75,
    boss_1_score: 17079,
    boss_2_usage: 3,
    boss_2_score: 14276,
    boss_3_usage: 45.93,
    boss_3_score: 20583
  },
  {
    char: 'koleda',
    name: 'Koleda',
    rarity: 'Standard 5*',
    dupes_score: 20148,
    current_usage: 6.07,
    prev_usage: 5.58,
    current_score: 12590,
    prev_score: 15860,
    boss_1_usage: 1.09,
    boss_1_score: 12964,
    boss_2_usage: 1.34,
    boss_2_score: 15524,
    boss_3_usage: 4.45,
    boss_3_score: 11672
  },
  {
    char: 'lighter',
    name: 'Lighter',
    rarity: 'Limited 5*',
    dupes_score: 28289,
    current_usage: 14.07,
    prev_usage: 15.23,
    current_score: 18912,
    prev_score: 22296,
    boss_1_usage: 4.8,
    boss_1_score: 19267,
    boss_2_usage: 1.44,
    boss_2_score: 18418,
    boss_3_usage: 9.5,
    boss_3_score: 18814
  },
  {
    char: 'lucy',
    name: 'Lucy',
    rarity: '4*',
    dupes_score: 29143,
    current_usage: 55.48,
    prev_usage: 59.58,
    current_score: 19809,
    prev_score: 23574,
    boss_1_usage: 10.24,
    boss_1_score: 21440,
    boss_2_usage: 4.21,
    boss_2_score: 19746,
    boss_3_usage: 48.81,
    boss_3_score: 19522
  },
  {
    char: 'lycaon',
    name: 'Lycaon',
    rarity: 'Standard 5*',
    dupes_score: 22430,
    current_usage: 32.51,
    prev_usage: 43.91,
    current_score: 17780,
    prev_score: 19897,
    boss_1_usage: 26.17,
    boss_1_score: 18145,
    boss_2_usage: 5.32,
    boss_2_score: 17093,
    boss_3_usage: 2.79,
    boss_3_score: 15414
  },
  {
    char: 'miyabi',
    name: 'Miyabi',
    rarity: 'Limited 5*',
    dupes_score: 31436,
    current_usage: 91.84,
    prev_usage: 91.83,
    current_score: 23254,
    prev_score: 25803,
    boss_1_usage: 80.65,
    boss_1_score: 23026,
    boss_2_usage: 4.8,
    boss_2_score: 26934,
    boss_3_usage: 11.03,
    boss_3_score: 23716
  },
  {
    char: 'nekomata',
    name: 'Nekomata',
    rarity: 'Standard 5*',
    dupes_score: 0,
    current_usage: 1.8,
    prev_usage: 2.18,
    current_score: 13627,
    prev_score: 16203,
    boss_1_usage: 0.41,
    boss_1_score: 10586,
    boss_2_usage: 0.61,
    boss_2_score: 15658,
    boss_3_usage: 1,
    boss_3_score: 13601
  },
  {
    char: 'nicole-demara',
    name: 'Nicole',
    rarity: '4*',
    dupes_score: 24728,
    current_usage: 48.46,
    prev_usage: 55.84,
    current_score: 21055,
    prev_score: 21004,
    boss_1_usage: 11.63,
    boss_1_score: 21166,
    boss_2_usage: 38.02,
    boss_2_score: 21418,
    boss_3_usage: 3.47,
    boss_3_score: 16305
  },
  {
    char: 'piper',
    name: 'Piper',
    rarity: '4*',
    dupes_score: 24260,
    current_usage: 22.4,
    prev_usage: 14.55,
    current_score: 19622,
    prev_score: 18838,
    boss_1_usage: 2.88,
    boss_1_score: 19205,
    boss_2_usage: 1.28,
    boss_2_score: 18013,
    boss_3_usage: 21.57,
    boss_3_score: 19768
  },
  {
    char: 'qingyi',
    name: 'Qingyi',
    rarity: 'Limited 5*',
    dupes_score: 21416,
    current_usage: 45.05,
    prev_usage: 48.36,
    current_score: 20962,
    prev_score: 21125,
    boss_1_usage: 2.62,
    boss_1_score: 15106,
    boss_2_usage: 46.5,
    boss_2_score: 21425,
    boss_3_usage: 0.77,
    boss_3_score: 10402
  },
  {
    char: 'rina',
    name: 'Rina',
    rarity: 'Standard 5*',
    dupes_score: 26961,
    current_usage: 35.3,
    prev_usage: 43.32,
    current_score: 19709,
    prev_score: 21726,
    boss_1_usage: 11.24,
    boss_1_score: 20923,
    boss_2_usage: 24.45,
    boss_2_score: 19640,
    boss_3_usage: 2.81,
    boss_3_score: 14568
  },
  {
    char: 'seth',
    name: 'Seth',
    rarity: '4*',
    dupes_score: 23496,
    current_usage: 19.7,
    prev_usage: 28.51,
    current_score: 15866,
    prev_score: 17812,
    boss_1_usage: 2.3,
    boss_1_score: 14222,
    boss_2_usage: 7.04,
    boss_2_score: 16923,
    boss_3_usage: 13.01,
    boss_3_score: 15496
  },
  {
    char: 'soldier-11',
    name: 'Soldier 11',
    rarity: 'Standard 5*',
    dupes_score: 22027,
    current_usage: 12.65,
    prev_usage: 4.48,
    current_score: 15168,
    prev_score: 16731,
    boss_1_usage: 0.23,
    boss_1_score: 0,
    boss_2_usage: 0.26,
    boss_2_score: 0,
    boss_3_usage: 14.25,
    boss_3_score: 15191
  },
  {
    char: 'soukaku',
    name: 'Soukaku',
    rarity: '4*',
    dupes_score: 22065,
    current_usage: 47.59,
    prev_usage: 62.41,
    current_score: 18395,
    prev_score: 20638,
    boss_1_usage: 39.31,
    boss_1_score: 18879,
    boss_2_usage: 6.48,
    boss_2_score: 16855,
    boss_3_usage: 4.32,
    boss_3_score: 16158
  },
  {
    char: 'yanagi',
    name: 'Yanagi',
    rarity: 'Limited 5*',
    dupes_score: 32203,
    current_usage: 53.23,
    prev_usage: 53.85,
    current_score: 23095,
    prev_score: 24057,
    boss_1_usage: 28.39,
    boss_1_score: 24914,
    boss_2_usage: 23.83,
    boss_2_score: 21320,
    boss_3_usage: 5.03,
    boss_3_score: 21777
  },
  {
    char: 'zhu-yuan',
    name: 'Zhu Yuan',
    rarity: 'Limited 5*',
    dupes_score: 21581,
    current_usage: 37.78,
    prev_usage: 39.45,
    current_score: 21292,
    prev_score: 21715,
    boss_1_usage: 4.57,
    boss_1_score: 16091,
    boss_2_usage: 36.32,
    boss_2_score: 22162,
    boss_3_usage: 0.77,
    boss_3_score: 13138
  }
];
