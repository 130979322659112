export const ZZZAssault_3 = [
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 13.74,
    rank: 1,
    avg_round: 23346,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 11.53,
    rank: 2,
    avg_round: 22767,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 9.4,
    rank: 3,
    avg_round: 22023,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 3.89,
    rank: 4,
    avg_round: 21031,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 3.3,
    rank: 5,
    avg_round: 19305,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 2.83,
    rank: 6,
    avg_round: 13949,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 2.55,
    rank: 7,
    avg_round: 28879,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 2.45,
    rank: 8,
    avg_round: 18024,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'caesar',
    app_rate: 2.21,
    rank: 9,
    avg_round: 14382,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 2.15,
    rank: 10,
    avg_round: 14393,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'astra-yao',
    app_rate: 1.89,
    rank: 11,
    avg_round: 17538,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 1.77,
    rank: 12,
    avg_round: 15110,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 1.38,
    rank: 13,
    avg_round: 10971,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.28,
    rank: 14,
    avg_round: 29655,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 1.26,
    rank: 15,
    avg_round: 16123,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 1.15,
    rank: 16,
    avg_round: 22836,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 1.02,
    rank: 17,
    avg_round: 23208,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.96,
    rank: 18,
    avg_round: 17049,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.94,
    rank: 19,
    avg_round: 22339,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'astra-yao',
    app_rate: 0.89,
    rank: 20,
    avg_round: 14690,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.89,
    rank: 20,
    avg_round: 20594,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.85,
    rank: 22,
    avg_round: 27724,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.85,
    rank: 22,
    avg_round: 11757,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.79,
    rank: 24,
    avg_round: 15594,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.79,
    rank: 24,
    avg_round: 20724,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.77,
    rank: 26,
    avg_round: 22995,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'soukaku',
    app_rate: 0.72,
    rank: 27,
    avg_round: 11558,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.7,
    rank: 28,
    avg_round: 24580,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.66,
    rank: 29,
    avg_round: 19281,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.6,
    rank: 30,
    avg_round: 19048,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'astra-yao',
    app_rate: 0.57,
    rank: 31,
    avg_round: 25712,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.47,
    rank: 32,
    avg_round: 11569,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.43,
    rank: 33,
    avg_round: 12250,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.4,
    rank: 34,
    avg_round: 16907,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'caesar',
    app_rate: 0.4,
    rank: 34,
    avg_round: 19076,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.38,
    rank: 36,
    avg_round: 16929,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.38,
    rank: 36,
    avg_round: 17973,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'rina',
    app_rate: 0.34,
    rank: 38,
    avg_round: 18117,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'seth',
    char_three: 'nicole-demara',
    app_rate: 0.34,
    rank: 38,
    avg_round: 11482,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'seth',
    app_rate: 0.32,
    rank: 40,
    avg_round: 10509,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.3,
    rank: 41,
    avg_round: 29819,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.28,
    rank: 42,
    avg_round: 33604,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.28,
    rank: 42,
    avg_round: 11480,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.28,
    rank: 42,
    avg_round: 20901,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'lucy',
    app_rate: 0.28,
    rank: 42,
    avg_round: 22404,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'lucy',
    app_rate: 0.28,
    rank: 42,
    avg_round: 21490,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.26,
    rank: 47,
    avg_round: 25399,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'caesar',
    app_rate: 0.26,
    rank: 47,
    avg_round: 10059,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'astra-yao',
    app_rate: 0.23,
    rank: 49,
    avg_round: 24786,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.23,
    rank: 49,
    avg_round: 12364,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.23,
    rank: 49,
    avg_round: 21302,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.23,
    rank: 49,
    avg_round: 13568,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.23,
    rank: 49,
    avg_round: 21217,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.23,
    rank: 49,
    avg_round: 31643,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.21,
    rank: 55,
    avg_round: 12502,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.21,
    rank: 55,
    avg_round: 16140,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.21,
    rank: 55,
    avg_round: 17416,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.21,
    rank: 55,
    avg_round: 15009,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.21,
    rank: 55,
    avg_round: 13002,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.19,
    rank: 60,
    avg_round: 17462,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.19,
    rank: 60,
    avg_round: 10286,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'soukaku',
    app_rate: 0.19,
    rank: 60,
    avg_round: 22664,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.19,
    rank: 60,
    avg_round: 11627,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.19,
    rank: 60,
    avg_round: 19828,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'caesar',
    app_rate: 0.19,
    rank: 60,
    avg_round: 8201,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.19,
    rank: 60,
    avg_round: 26490,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.19,
    rank: 60,
    avg_round: 21585,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 68,
    avg_round: 12395,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.17,
    rank: 68,
    avg_round: 11278,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.17,
    rank: 68,
    avg_round: 21010,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.17,
    rank: 68,
    avg_round: 23380,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.17,
    rank: 68,
    avg_round: 13568,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.15,
    rank: 73,
    avg_round: 18624,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'soukaku',
    app_rate: 0.15,
    rank: 73,
    avg_round: 25090,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'koleda',
    char_three: 'ben',
    app_rate: 0.15,
    rank: 73,
    avg_round: 8391,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 73,
    avg_round: 18415,
    star_num: '4'
  },
  {
    char_one: 'zhu-yuan',
    char_two: 'qingyi',
    char_three: 'nicole-demara',
    app_rate: 0.15,
    rank: 73,
    avg_round: 8301,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.15,
    rank: 73,
    avg_round: 20798,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'caesar',
    app_rate: 0.15,
    rank: 73,
    avg_round: 18833,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'lighter',
    app_rate: 0.15,
    rank: 73,
    avg_round: 13350,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.15,
    rank: 73,
    avg_round: 27891,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'burnice',
    app_rate: 0.15,
    rank: 73,
    avg_round: 18344,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.13,
    rank: 83,
    avg_round: 18264,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'rina',
    app_rate: 0.13,
    rank: 83,
    avg_round: 16530,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'lighter',
    app_rate: 0.13,
    rank: 83,
    avg_round: 18877,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'lucy',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 83,
    avg_round: 10977,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 83,
    avg_round: 16169,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lycaon',
    char_three: 'astra-yao',
    app_rate: 0.13,
    rank: 83,
    avg_round: 14871,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.13,
    rank: 83,
    avg_round: 16300,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 83,
    avg_round: 14568,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.13,
    rank: 83,
    avg_round: 20427,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'caesar',
    app_rate: 0.13,
    rank: 83,
    avg_round: 18792,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.13,
    rank: 83,
    avg_round: 24491,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 94,
    avg_round: 20004,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'astra-yao',
    app_rate: 0.11,
    rank: 94,
    avg_round: 17261,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lycaon',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 94,
    avg_round: 23326,
    star_num: '4'
  },
  {
    char_one: 'lighter',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.11,
    rank: 94,
    avg_round: 18092,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 94,
    avg_round: 22565,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'soukaku',
    char_three: 'nicole-demara',
    app_rate: 0.11,
    rank: 94,
    avg_round: 26793,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.11,
    rank: 94,
    avg_round: 16203,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 94,
    avg_round: 11323,
    star_num: '4'
  },
  {
    char_one: 'koleda',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.11,
    rank: 94,
    avg_round: 9651,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'qingyi',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 94,
    avg_round: 9372,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'lucy',
    char_three: 'ben',
    app_rate: 0.11,
    rank: 94,
    avg_round: 14215,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'rina',
    char_three: 'lucy',
    app_rate: 0.11,
    rank: 94,
    avg_round: 21483,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'seth',
    app_rate: 0.11,
    rank: 94,
    avg_round: 10595,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.11,
    rank: 94,
    avg_round: 8428,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.11,
    rank: 94,
    avg_round: 28104,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'yanagi',
    char_three: 'burnice',
    app_rate: 0.11,
    rank: 94,
    avg_round: 21403,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 110,
    avg_round: 21822,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 110,
    avg_round: 17121,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'lucy',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 110,
    avg_round: 17322,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'astra-yao',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 110,
    avg_round: 14684,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'koleda',
    app_rate: 0.09,
    rank: 110,
    avg_round: 14487,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 110,
    avg_round: 17776,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'koleda',
    char_three: 'soukaku',
    app_rate: 0.09,
    rank: 110,
    avg_round: 6797,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.09,
    rank: 110,
    avg_round: 19378,
    star_num: '4'
  },
  {
    char_one: 'corin',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 110,
    avg_round: 9253,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 110,
    avg_round: 13233,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'astra-yao',
    char_three: 'ben',
    app_rate: 0.09,
    rank: 110,
    avg_round: 11233,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'jane-doe',
    char_three: 'caesar',
    app_rate: 0.09,
    rank: 110,
    avg_round: 10562,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'burnice',
    char_three: 'lucy',
    app_rate: 0.09,
    rank: 110,
    avg_round: 14380,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 123,
    avg_round: 14585,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'lighter',
    char_three: 'nicole-demara',
    app_rate: 0.06,
    rank: 123,
    avg_round: 14096,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'jane-doe',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 123,
    avg_round: 20034,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 123,
    avg_round: 17532,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'jane-doe',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 123,
    avg_round: 13956,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 123,
    avg_round: 14775,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'burnice',
    char_three: 'ben',
    app_rate: 0.06,
    rank: 123,
    avg_round: 14535,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 123,
    avg_round: 14532,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 123,
    avg_round: 14168,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'nicole-demara',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 123,
    avg_round: 17751,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'nicole-demara',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 123,
    avg_round: 20176,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'koleda',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 123,
    avg_round: 11543,
    star_num: '4'
  },
  {
    char_one: 'burnice',
    char_two: 'grace-howard',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 123,
    avg_round: 17756,
    star_num: '4'
  },
  {
    char_one: 'soldier-11',
    char_two: 'nicole-demara',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 123,
    avg_round: 12677,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 123,
    avg_round: 17070,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'harumasa',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 123,
    avg_round: 14431,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'seth',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 123,
    avg_round: 9071,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'piper',
    char_three: 'soukaku',
    app_rate: 0.06,
    rank: 123,
    avg_round: 12846,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'seth',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 123,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 123,
    avg_round: 29204,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'qingyi',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 123,
    avg_round: 11414,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'grace-howard',
    char_three: 'astra-yao',
    app_rate: 0.06,
    rank: 123,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'ellen',
    char_two: 'lighter',
    char_three: 'rina',
    app_rate: 0.06,
    rank: 123,
    avg_round: 7402,
    star_num: '4'
  },
  {
    char_one: 'yanagi',
    char_two: 'burnice',
    char_three: 'seth',
    app_rate: 0.06,
    rank: 123,
    avg_round: 22609,
    star_num: '4'
  },
  {
    char_one: 'piper',
    char_two: 'burnice',
    char_three: 'ben',
    app_rate: 0.06,
    rank: 123,
    avg_round: 18385,
    star_num: '4'
  },
  {
    char_one: 'nekomata',
    char_two: 'piper',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 123,
    avg_round: 15809,
    star_num: '4'
  },
  {
    char_one: 'jane-doe',
    char_two: 'lighter',
    char_three: 'lucy',
    app_rate: 0.06,
    rank: 123,
    avg_round: 9923,
    star_num: '4'
  },
  {
    char_one: 'miyabi',
    char_two: 'burnice',
    char_three: 'caesar',
    app_rate: 0.06,
    rank: 123,
    avg_round: 17103,
    star_num: '4'
  }
];
